<template>
  <v-form ref="form" @submit.prevent="validateBeforeSubmit">
    <v-card>
      <v-card-title class="primary white--text">New Alert</v-card-title>
      <v-card-text>
        <v-col class="d-flex" cols="3">
          <v-select
            :items="alertType"
            label="Alert Type"
            v-model="vm.type"
            data-vv-as="type"
            name="type"
            v-validate="'required'"
            :error-messages="errors.collect('type')"
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-text-field
            filled
            label="Description"
            v-model="vm.description"
            data-vv-as="description"
            name="description"
            v-validate="'required'"
            :error-messages="errors.collect('description')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            filled
            label="Procedure"
            v-model="vm.procedure"
            data-vv-as="procedure"
            name="procedure"
            v-validate="'required'"
            :error-messages="errors.collect('procedure')"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-menu
            ref="expiryMenu"
            v-model="expiryMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="vm.expiry"
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="vm.expiry"
                label="Expiry"
                v-on="on"
                readonly
                v-validate="'required'"
                data-vv-as="expiry"
                name="expiry"
                :error-messages="errors.collect('expiry')"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="vm.expiry"
              no-title
              scrollable
              color="green lighten-1"
              dark
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="expiryMenu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.expiryMenu.save(vm.expiry)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Cancel</v-btn>
        <v-btn color="primary" type="submit">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as alertsService from "../../../services/alertsService";
import * as firebaseEmailService from "../../../services/firebaseEmailService";
import * as notificationService from "../../../services/notificationService";

Vue.use(VeeValidate);
export default {
  name: "alerts-form",

  data: () => ({
    vm: {},
    expiryMenu: false,
    form: {},
    emails: [],
    alertType: ["Physical", "Medical", "Behavioural", "Emotional", "General"],
  }),
  watch: {
    alert(value) {
      if (value !== null && value !== undefined) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
  props: {
    alert: Object,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
  },
  methods: {
    async init() {
      if (this.alert !== undefined) {
        this.vm = this.alert;
      }
      await notificationService.getAlertById(this.currentOrganisation.id).then((doc) => {
        let temp = [];
        temp = doc.data();
        this.emails = temp.Emails;
        console.log(this.emails);
      });
    },
    updateExpiryDatetime: function (datetime) {
      this.vm.expiry = datetime;
    },
    close() {
      this.$emit("alert-closed");
    },
    validateBeforeSubmit() {
      let me = this;
      this.$validator.validateAll().then((result) => {
        if (result) {
          me.vm.expiryDate = new Date(me.vm.expiry);

          alertsService
            .save(me.currentOrganisation.id, me.currentCase.id, me.vm)
            .then((ref) => {
              if (ref != undefined) {
                me.vm.id = ref.id;
              }
              this.sendEmail();

              me.$store.dispatch("setToast", {
                message: "alert saved",
                color: "primary",
              });
              me.$emit("alert-added", me.vm);
            });
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4",
          });
        }
      });
    },
    sendEmail() {
      let me = this;

      let participantFullname =me.currentCase.participantDetails.firstname +" " +me.currentCase.participantDetails.lastname;

      let fromID = me.user.email;
      let fromName = me.user.displayName;
      let toID = this.emails;
      let subject = "Omar notifications - new alert";
      let text = participantFullname + " has new " + me.vm.type + " alert";

      let sendEmail = "Do you want to send an Email?";

      if (confirm(sendEmail) == true) {
        firebaseEmailService.sendAlert(fromID, fromName, toID, subject, text);
      } else {
        console.log("not sent");
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  color: green;
  font-weight: bold;
}
</style>
